import React, {useCallback, useMemo} from 'react';
import { Page, LegacyCard, LegacyStack, Layout, Button, Text } from "@shopify/polaris";
import {navigate} from 'gatsby';
import './checkoutExtensionGuide.css';
import Onboarding from '../../../../components/onBoarding';

const CheckoutExtensionGuide = (props) => {
  const {location} = props;
  const rules = location && location.state && location.state.rules;
  const goToCustomization = useCallback(() => {
    navigate("/app/customizations", {
      state: location.state,
      replace: true,
    })
  }, [rules]);

  
  return (
    <Page backAction={{
                content: 'Customizations',
                onAction: goToCustomization}} 
                title="Create Customization">
      <Layout>
        <Layout.Section>
          <Onboarding rules={rules} customization/>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default CheckoutExtensionGuide;