import React, { useState, useCallback, useContext } from "react"
import { LegacyStack, Text, InlineGrid } from "@shopify/polaris";
import { navigate } from "gatsby"
import { doc } from 'firebase/firestore';
import { useDocumentData } from "react-firebase-hooks/firestore"
import FirebaseContext from "../providers/firebase"
import getCheckoutProfiles from "../helpers/getCheckoutProfiles"
import CustomizationSelectionCard from "./customizationSelectionCard"

const allCheckout = [
  "shopify_plus",
  "affiliate",
  "partner_test",
  "plus_partner_sandbox",
  "staff",
  "staff_business",
  "custom",
  "unlimited",
]
const Onboarding = (props) => {
  const { rules, customization } = props
  const { firebase, shop, token, host } = useContext(FirebaseContext)
  const [loading, setLoading] = useState(false)
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc,
    {
      transform: (snap) => snap.shopData,
    }
  )
  const createOfferHandler = async () => {
    let checkoutProfiles
    try {
      setLoading(true)
      checkoutProfiles = await getCheckoutProfiles(token, shop, host)
      console.log("checkoutProfiles", checkoutProfiles)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
    if (checkoutProfiles && checkoutProfiles.data.checkoutProfiles) {
      /** Created a check for a published checkout profile and divided it into two options for the future
       * if we need to use different behavior */
      if (
        !checkoutProfiles.data.checkoutProfiles.edges.some(
          ({ node }) => node.isPublished
        )
      ) {
        navigate("/app/offers/createCheckoutType", {
          state: { rules },
          replace: true,
        })
      } else {
        navigate("/app/offers/createCheckout", {
          state: { rules },
          replace: true,
        })
      }
    } else {
      navigate("/app/offers/create", {
        state: { rules },
        replace: true,
      })
    }
  }

  const createCartOfferHandler = useCallback(() => {
    navigate("/app/offers/createCartType", {
      state: { rules },
      replace: true,
    })
  }, [rules])

  const createPostPurchaseOfferHandler = useCallback(() => {
    navigate("/app/offers/createPostPurchase", {
      state: { rules },
      replace: true,
    })
  }, [rules])
  const createCustomBannerHandler = useCallback(() => {
    navigate("/app/customizations/customcontent/createCustomContent", {
      state: {
        backLink: "/app/customizations",
        extensionName: "Custom Content",
        title: "Create Custom Banner",
      },
      replace: true,
    })
  }, [])
  const createCustomImageHandler = useCallback(() => {
    navigate("/app/customizations/customcontent/createCustomContent", {
      state: {
        backLink: "/app/customizations",
        extensionName: "Custom Content",
        title: "Create Custom Image",
      },
      replace: true,
    })
  }, [])
  const createPaymentIconsHandler = useCallback(() => {
    navigate("/app/customizations/customcontent/createCustomContent", {
      state: {
        backLink: "/app/customizations",
        extensionName: "Custom Content",
        title: "Create Payment Icons",
      },
      replace: true,
    })
  }, [])

  const createCustomFieldHandler = useCallback(() => {
    navigate("/app/customizations/customfield/createCustomField", {
      replace: true,
    })
  }, [])

  const createRewardsBarHandler = useCallback(() => {
    navigate(`/app/customizations/customizationPreview`, {
      state: {
        backLink: "/app/customizations",
        extensionName: "Rewards Bar",
      },
      replace: true,
    })
  }, [])

  const learnMoreHandler = useCallback(() => {
    window.open(
      `https://help.upsellplus.com/en/article/how-to-add-a-free-shipping-bar-to-your-checkout-ibr8aj/`,
      "_blank"
    )
  }, [])

  const createAddressBlockerHandler = useCallback(() => {
    navigate("/app/customizations/addressblocker/createAddressBlocker", {
      replace: true,
    })
  }, [])

  const createHidePaymentMethodsHandler = useCallback(() => {
    navigate("/app/customizations/hidepaymentmethods/createHidePaymentMethods", {
      replace: true,
    })
  }, [])

  const createHideDeliveryMethodsHandler = useCallback(() => {
    navigate("/app/customizations/hidedeliverymethods/createHideDeliveryMethods", {
      replace: true,
    })
  }, [])

  const createCheckoutBrandingHandler = useCallback(() => {
    navigate("/app/settings/branding", {
      state: {
        backLink: "customizations",
      },
      replace: true,
    })
  }, [])

  const CONTENT_SELECTION = [
    {
      title: "Banner",
      content: "Show info or a warning",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Banner-onboarding-v2.png?alt=media&token=0d14f1ca-6ad0-4ed3-b155-a25440ae51b6",
      url: createCustomBannerHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Image",
      content: "Show an image",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Image-onboarding-v2.png?alt=media&token=c317432c-ae32-4c2c-9d3d-5e0b7d0b9852",
      url: createCustomImageHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Gift note",
      content: "Give the option to leave a gift note",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Gift-notes-onboarding-v2.png?alt=media&token=30982923-7f56-4853-b9f8-b603785f03b4",
      url: createCustomFieldHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Rewards bar",
      content: "Add a free shipping or free gift progress bar",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Rewards-bar-onboarding-v2.png?alt=media&token=2d5e819a-58e0-443c-8450-0cede8330eb8",
      url: createRewardsBarHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
      learnMore: learnMoreHandler,
    },
    {
      title: "Payment icons",
      content: "Show payment icons to instill trust",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Payment-icons-onboarding-v2.png?alt=media&token=eeab43ce-0990-425a-b097-3ed4a5ff6b65",
      url: createPaymentIconsHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    // {
    //   title: "Trust badge",
    //   content: "Add trust badges",
    //   image:
    //     "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/trust-badge-selection.jpg?alt=media&token=7be735e3-b60b-4fc0-bd17-520b7bbce66f",
    //   url: createCustomContentHandler,
    //   grey:
    //     shopData &&
    //     shopData.plan_name &&
    //     allCheckout.indexOf(shopData.plan_name) === -1,
    // },
    
  ]

  const PAYMENT_AND_DELIVERY_SELECTION = [
    {
      title: "Hide payment methods",
      content: "Hide payment methods",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Hide-payment-onboarding-v2.png?alt=media&token=1aa05e6f-4153-429c-983d-58324cc25547",
      url: createHidePaymentMethodsHandler,
    },
    {
      title: "Hide delivery methods",
      content: "Hide delivery methods",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Hide-delivery-onboarding-v2.png?alt=media&token=3061aa95-fba3-4411-869e-774dd29017f2",
      url: createHideDeliveryMethodsHandler,
    },
    {
      title: "Address validator",
      content: "Validate address fields and block checkout",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/address-validator-onboarding-v2.png?alt=media&token=73a47640-7a15-400d-95d7-51ceee5fb0e7",
      url: createAddressBlockerHandler,
    },
  ]

  const BRANDING_AND_STYLE_SELECTION = [
    {
      title: "Branding and styling",
      content: "Control the colors, fonts and styling of your checkout",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/branding-and-styleing-selection.jpg?alt=media&token=83b95843-9b0a-4b2f-885c-0ad5b9b3dc62",
      url: createCheckoutBrandingHandler,
    },
  ]

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ padding: "0 0 15px 0" }}>
            <Text variant="headingLg" as="h2">Content</Text>
          </div>
          <InlineGrid
               gap="400"
               columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}>
            {CONTENT_SELECTION.map((item, index) => {
              return <CustomizationSelectionCard key={index} {...item} />
            })}
          </InlineGrid>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "100px",
        }}
      >
        <div>
          <div style={{ padding: "15px 0" }}>
            <Text variant="headingLg" as="h2">Payment and delivery</Text>
          </div>
          <InlineGrid
               gap="400"
               columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}>
            {PAYMENT_AND_DELIVERY_SELECTION.map((item, index) => {
              return <CustomizationSelectionCard key={index} {...item} />
            })}
          </InlineGrid>
        </div>
      </div>
    </div>
  );
}

export default Onboarding
